header.banner {
  h1 {
    @apply text-6xl font-thin;
    font-family: 'Shadows Into Light', cursive;
  }

  .subtitle {
    @apply justify-between flex-none text-sm sm:flex;

    .text {
      @apply px-2 mb-2 text-gray-500;
    }

    a {
      @apply underline;
    }
  }
}

main.main {
  @apply bg-white rounded-sm shadow-sm;

  section#taskSection {
    @apply justify-center pt-4 pb-8;

    form {
      @apply flex justify-center;

      input#taskInput {
        @apply w-8/12 px-3 py-2 text-xl placeholder-gray-300 border border-gray-300 rounded-l sm:w-10/12 sm:px-5 sm:py-3 sm:text-2xl focus:outline-none;
      }

      button {
        @apply px-6 py-2 text-xl rounded-sm rounded-r bg-rose-500 text-rose-100 hover:bg-rose-400;
      }
    }
  }
}

.navbar {
  @apply flex justify-end pt-6 pb-3;

  a {
    @apply px-2 py-1 text-xl text-gray-800 border-r border-gray-300 sm:px-4 hover:text-gray-500 last:border-none;
  }
}
