section.todo-list {
  @apply px-4 my-2 bg-white rounded-sm shadow-sm sm:px-8;

  ul.items {
    @apply py-1;

    input[type="checkbox"] {
      @apply mr-2 border border-gray-400 rounded form-checkbox;
    }

    li {
      @apply flex items-center justify-between py-5 mb-4 text-lg border-b border-gray-200 sm:text-2xl last:border-none last:mb-0;

      &.completed {
        .item-control {
          .edit {
            @apply hidden;
          }
        }

        .content {
          @apply text-gray-400 line-through;
        }
      }
    }

    .item-control {
      @apply flex;

      a {
        @apply px-2 py-2 mx-1 bg-gray-100 rounded-sm sm:px-4 sm:py-3 hover:bg-gray-200;
      }
    }

    .item-content {
      @apply flex items-center sm:px-2;

      label {
        @apply flex items-center px-2 cursor-pointer;
      }
    }
  }
}
