footer {
  p {
    @apply block px-2 my-4 text-sm text-center text-gray-500 sm:hidden;
  }
}

.icon {
  @apply w-3 h-3 sm:w-4 sm:h-4;
}

a.text-link {
  @apply underline underline-offset-4;

  &:hover {
    @apply text-rose-500;
  }
}
